.complete-registration {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .modal {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span{
            color: var(--light-font-color);
        }
    }
}