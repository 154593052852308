.payment {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .content {
    width: 100%;
    max-width: 1024px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    h1 {
      align-self: flex-start;
    }

    .product {
      width: 100%;
      :first-of-type {
        font-weight: 600;
      }
    }
  }
}
