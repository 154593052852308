*{
  box-sizing: border-box;
}

.change-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/img/authBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;

  .change-password-card{
    width: 30%;
    padding: 25px 20px 30px 20px;
  }


  .content {
    width: 100%;
    max-width: 1024px;

    position: relative;
    display: flex;
    flex-direction: column;

    gap: 18px;
    color: var(--light-font-color);

    form {

      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 20px;


      .input-container {
        display: flex;
        flex-direction: column;
        position: relative;


      }
    }

    .actions {
      display: flex;
      gap: 10px;

      * {
        flex: 1;
      }
    }

  }


}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin: 10px 0;
  }
}