.payment-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--navbar-height);
  .loading {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-content {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 2rem;
    flex-grow: 1;
    padding: 2rem 4rem;

    .payment{
      display: flex;
      align-items: start;
      width: 100%;
      gap: 2rem;

      div {
        flex-basis: 100%;
        gap: 20px;
      }
    }

    .product-details {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.1) 0px 0px 0px 1px;
      border-radius: 10px;
      padding: 10px 20px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          margin: 0 0 10px 0;
          color: var(--custom-vr-main-color);
        }

        .price {
          padding: 5px 15px;
          border-radius: 50px;
          border: 1px solid var(--custom-vr-main-color);
          background: #f94c101c;
          color: var(--custom-vr-main-color);
        }
      }
   

      .change-conatiner{
        display: flex;
        justify-content: flex-end;
        .change{
            border-radius: 5px;
          }
      }
    }
  }
  .page-title{
    width: 100%;
  }
}
