.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3 {
    font-size: 1.25rem;
    margin: 0;
  }

}
