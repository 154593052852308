.label {
  color: #fff !important;
}

.select {
  width: 400px !important;
  color: #fff !important;
}

.item {
  color: #fff !important;
}
