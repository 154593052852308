.payment-form{ 
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .save-card{
        position: absolute;
        background: white;
        color: black;
        bottom: 25%;
        // bottom: 39%;
        left: 4%;
}

}

.submit{
    background: var(--custom-vr-main-color);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
}

.payment-methods{

    .title{
        color: var(--custom-vr-main-color);
    }
    .last4{
        color: #939191;
    }
}

.modal-button{
    flex-basis: 100%;
    border-radius: 6px;
}

.done{
    img{
        width: 100px;
        height: 100px;
    }
    
}
