.container {
  overflow: hidden;
}

.header {
  position: relative;
  height: 100vh;
  overflow: hidden;

  &-wrap {
    width: 100vw;
    height: 100%;
    background-color: rgba(#333, 0.6);
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5.3rem;
  }

  &-text {
    position: absolute;
    left: 10%;
    top: 500px;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
  }

  &-subtitle {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
    animation: changeBG 20s infinite;
  }

  .bg1 {
    background-image: url("../../assets/img/home_bg/home_bg_1.webp");
    animation-delay: 0s;
  }

  .bg2 {
    background-image: url("../../assets/img/home_bg/home_bg_2.webp");
    animation-delay: 5s;
  }

  .bg3 {
    background-image: url("../../assets/img/home_bg/home_bg_3.webp");
    animation-delay: 10s;
  }

  .bg4 {
    background-image: url("../../assets/img/home_bg/home_bg_4.webp");
    animation-delay: 15s;
  }
}

@keyframes changeBG {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.title {
  color: var(--custom-vr-main-color);
  margin: 70px 50px;
}

.video-slider {
  margin-top: 100px;
}
