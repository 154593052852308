.session-history {
  display: flex;
  flex-direction: column;

  .add-patient-btn {
    align-self: flex-end;
    margin-bottom: 20px;

    span {
      color: #000;
      padding: 0 10px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .session-row {
      position: relative;
    }

    .accordion-summary {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        flex: 1;
        font-weight: 500;
      }

      &.active {
        span {
          color: var(--custom-vr-main-color);
        }
      }
    }

    .edit-patient {
      font-weight: bold;
    }

    .accordion-details {
      table {
        border-collapse: collapse;
        // background: var(--background-color);
        width: 100%;
        margin-top: 20px;
        thead {
          th {
            border-bottom: 1px solid var(--custom-vr-main-color);
          }
        }
        th,
        td {
          width: calc(100% / 4);
          padding: 12px;
          text-align: center;
          border-bottom: 1px solid var(--border-color);
        }
      }

      .session-videos {
        list-style: none;
        display: flex;
        flex-direction: column;

        gap: 10px;
        padding: 0;

        .videos-history {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;

          .video-duration {
            padding: 5px 0;
            width: 30%;
            background: var(--background-color);
            color: var(--custom-vr-main-color);
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            font-size: 0.725rem;
            font-weight: bold;
          }
        }
      }

      .session-note {
        max-height: 150px;
        overflow: hidden;
        padding: 10px;
        background: var(--background-color);
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        cursor: Pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.36) 0px 1px 4px;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 99;
    border-radius: 10px;
  }

  .note {
    border-radius: 10px;
    background: var(--background-color);
    padding: 10px;
    height: 85%;
    max-height: 85%;
    overflow-y: scroll;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .note-content {
    white-space: pre-wrap;
  }
  .note-buttons {
    display: flex;
    gap: 10px;
  }
  .note-textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    outline: none;
    background: transparent;
  }
}
.close-modal {
  position: absolute;
  top: -9%;
  right: -6%;
  background: var(--background-color);
  color: black;
  padding: 16px 20px;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: red;
    color: white;
    transition: all 0.2s ease-out;
  }
}
