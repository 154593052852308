.register {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  color: var(--light-font-color);

  span{
    color: var(--light-font-color);
  }
  
  .register-header {
    display: flex;
    align-items: center;
  }
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input-container {
      display: flex;
      flex-direction: column;

      span {
        color: red;
      }
    }
  }
  .login-from-register {
    text-align: center;
    font-size: 0.75rem;
  }
  .register-footer {
    font-size: 0.75rem;
    .confirmation {
      display: flex;
      align-items: center;
      color: var(--custom-vr-main-color);

      input {
        accent-color: var(--custom-vr-main-color);
      }
    }
  }
  .error,
  span {
    font-size: 0.75rem;
    color: var(--light-font-color);

  }
}

.succes {
  width: 100%;
  background-color: #ffffffbf;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
  }
}
