.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
}

.modal-card {
  width: fit-content;
}

.box {

}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }
}

.select {
  margin-top: 10px;
  margin-bottom: 30px;
}

.add-button {
  margin-bottom: 30px;
  width: 100%;
  
}

.start-button {
  margin-bottom: 20px;
}

.subscription-box{
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subscription {
  &-box {
    width: 400px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-text {
    font-size: 0.9rem;
    color: #fff;
    margin-top: 20px;
  }

  &-button {
    margin-top: 30px;
  }
  
}
