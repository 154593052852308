.footer {
  width: 100%;
  background-color: var(--light-background-overlay);
  box-shadow: rgba(17, 17, 26, 0.1) 0px -1px 0px;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem 4rem;


  .content {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    * {

      text-decoration: none;
      font-size: 1rem;
    }

    > div {
      display: flex;
      flex-direction: column;

    }

    .links {
      display: flex;

    }

    .payment{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      img{
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
      .iyzico{
        width: 80px;
      }
    }
  }



  .copyright{
    width: 100%;
    text-align: center;
    font-size: 0.8rem;

  }
}
