.notification {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 20px;

  gap: 18px;
  flex-grow: 1;

  .buttons {
    display: flex;
    gap: 20px;
  }

  .empty-notification {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification-field {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
