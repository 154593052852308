.container{
  width: 100vw;
  height: 100vh;
  background: #1e1c1c;
  *{
    color: white;
  }
  .videoContainer {
    position: relative;
  
 
    .video {
      // height: 300px;
      padding-top: 52%;
    }
  
    .patientInformationContainer {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      z-index: 99;
  
      p{
        background: white;
        padding: 5px 10px;
        border: 1px solid var(--custom-vr-main-color);
        border-radius: 5px;
        color: var(--custom-vr-main-color);
      }
    }
  
  
  .overlay{
    position: absolute;
    top: 0;
    background: black;
    z-index: 999;
    width: 100%;
    height: 100%;
  
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  }
}

.controlButton{
     position: absolute !important;
    top: 10px;
    left: 10px;
    z-index: 999;
    border-radius: 5px !important;
}

