.membership {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;

  .content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-radius: 10px;


    span{
      color: var(--text-gray-color);
    }

    .section {
      .section-title{
        margin-top: 0;
        display: flex;
        flex-direction: column;
      }


    .cancel-button {
      &:hover {
        span {
          color: white !important;
        }
        }
      }
    }

  }
  .membership-action-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 20%;
    
    .button{
      flex-basis: 100% !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
    

  }
  .not-a-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.cancel-memberships-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem !important;
  background: red;

  h3,
  p {
    text-align: center;
    margin: 0;
  }


  .popup-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popup-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.payment-methods{
   .title{
    display: flex;
    align-items: center;
    gap: 20px;
    
    h1{
      margin: 0;
    }
   }
}


}
.add-payment-method-card{
  height: 50%;
  padding: 30px 20px !important;
}