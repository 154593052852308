:root {
  --custom-vr-main-color: #f94c10;
  --secondary-color: rgba(0, 0, 0, 0.5);
  --background-color: rgba(240, 240, 240, 0.8);
  --border-color: rgba(119, 133, 154, 0.3);
  --danger-color: rgb(187, 33, 36);

  --light-background-overlay: rgba(255, 255, 255, 0.9);
  --light-font-color: #F5F5F5;

  --light-primary-background: rgb(249 76 16 / 15%);
  --text-gray-color: #77859A;


  --navbar-height: 60px;
  --footer-height: 100px;
  --side-bar-width: 20%;
}
