.account {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--navbar-height);

  .content {
    width: 100%;

    flex-grow: 1;
    display: flex;
    

    .side-bar-container {
      padding-top: 70px;
    }

    .outlet-container {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 2rem;
      padding: 1.9rem 2rem 2rem 0;

      h1 {
        margin-top: 0;
      }
    }
  }
}
