.login {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: var(--light-font-color);
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .input-container {
      display: flex;
      flex-direction: column;
      position: relative;

      span {
        color: red;
      }
    }
  }
  .login-footer {
    font-size: 0.75rem;
  }
}
