.submit{
    background: var(--custom-vr-main-color);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}