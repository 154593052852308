.profile {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .avatar {
    width: max-content;
    position: relative;

    img {
      border-radius: 100%;
      width: 5rem;
      height: 5rem;
    }

    svg {
      color: #fff;
      background: var(--custom-vr-main-color);
      border-radius: 100%;
      padding: 2px;
      font-size: 1rem;
      position: absolute;
      bottom: 4px;
      right: 5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

  .personal-info-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h3 {
        margin: 0;
        text-transform: capitalize;
      }
  
   
    }
  
    .personal-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2rem;

      div:first-child {
        flex-wrap: wrap;
      }
  
      .info-row {
        max-width: 350px;
        display: flex;
        flex-direction: column;
  
        .row-key {
          font-weight: 700;
        }
  
        span {
          padding: 5px 0;
          color: var(--text-gray-color);
        }
      }
  
      .left,
      .right {
        display: flex;
        gap: 16px;
        flex-direction: column;
        flex: 1;
      }
  
      .right {
        flex: 2;
      }
    }
  }


  .change-password {
    color: var(--custom-vr-main-color);
    font-weight: 700;
    width: 100%;
    text-align: center;
  }
  .edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 0.8rem;
    color: var(--custom-vr-main-color);
  
    svg {
      font-size: 1rem;
    }
  
    &:hover {
      svg {
        color: white;
      }
  
      .action-button {
        color: white;
      }
    }
  }
  
}

::file-selector-button {
  display: none;
}
