.overlay {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  z-index: 1;

  svg {
    color: var(--custom-vr-main-color);
  }
}
