.notification-count {
    position: absolute;
    top: -2px;
    right: 0px;
    width: 12px;
    height: 12px;
    background: red;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}