.terms{
    padding: calc(var(--navbar-height) + 2rem) 2rem;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - (var(--navbar-height) + 60px));

    .conent{
        width: 60%;
    }
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}