.additional-products {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .session-input-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .buy-session {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }


  input::-webkit-input-placeholder {
    color: black; /*Change the placeholder color*/
    opacity: 0.5; /*Change the opacity between 0 and 1*/
}

