.remove-account {
  .content {
    max-width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    p {
      color: var(--light-font-color);
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
    }
  }
}
