.payment-methods {
  list-style: none;

  li {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      .edit {
        display: block;
      }
    }
  }

  .default {
    border: 1px solid var(--custom-vr-main-color);
    color: var(--custom-vr-main-color);
  }
  .edit {
    border: 1px solid blue;
  }

  .popper {
    border-radius: 10px !important;
    button{
        padding: 20px 0;
    }
  }
}
