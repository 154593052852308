.info-row {
  max-width: 350px;
  display: flex;
  flex-direction: column;

  .row-key {

  }

  span {
    color: var(--text-gray-color);
   
  }
}
