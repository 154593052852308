* {
  box-sizing: border-box;
}
.subscription-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  gap: 20px;

  h4,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  .subscription-types {
    display: flex;
    justify-content: end;
    gap: 5px;
    width: 100%;
  }

  .products-container {
    width: 100%;
    display: flex;
    gap: 2rem;

    .products {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 0;
      margin: 0;

      .disable-plan {
        background: rgba(0, 0, 0, 0.1) !important;
        color: rgba(0, 0, 0, 0.5) !important;

        &:hover {
          background: rgba(0, 0, 0, 0.2) !important;
          color: rgba(0, 0, 0, 0.5) !important;
        }
      }
      .selected {
        background: var(--custom-vr-main-color);
        color: white;
      }
    }

    .products,
    .plan-information-container {
      flex-basis: 100%;
    }
  }

  .subcription-type-button {
    color: black;
    padding: 5px 25px;
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 5px;
  }



  .active {
    background: var(--light-primary-background);
    border: none;
    color: var(--custom-vr-main-color);
    
  }

  .plan-information-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    flex-grow: 1;
    border-radius: 10px;
    padding: 2rem 1rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 30px;

      h4 {
        text-align: end;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        padding: 5px 20px;
        font-size: 12px;
        border-radius: 5px;
      }

      .details-container {
        width: 100%;

        max-height: 22vh;
        overflow-y: scroll;
        margin-bottom: 1rem;

        ul {
        }
      }
    }
  }

  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 25%;
    height: 350px;

    border-radius: 5px;
    position: relative;
    padding: 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transition: all 0.2s;
    background: #f6f6f6;

  }
}

.current-plan {
  background: var(--custom-vr-main-color) !important;
  color: white !important;
}

.info-text {
  font-size: 12px;
  white-space: normal; /* Allows the text to wrap */
  word-wrap: break-word; /* Breaks long words to wrap to the next line */
  overflow-wrap: break-word; 
}