.top-bar {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;
        gap: 40px;
        width: 100%;

        span {
            font-size: 1rem;
            width: max-content;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis;
        }
    }
    .right {
        display: flex;
    }
}

@media only screen and (max-width: 900px) {
    .top-bar {
        padding: 50px 15px;
        .left {
            gap: 20px;
            span {
                font-size: 1.2rem;
            }
        }
    }
}
