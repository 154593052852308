.create-password {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .create-password-form {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .input-container {
      display: flex;
      flex-direction: column;
    }
  }
}

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    color: var(--custom-vr-main-color);
  }
}

.password-updated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--custom-vr-main-color);
    font-size: 4rem;
  }
}