.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  padding: 10px 20px;
  border-radius: 10px;
  flex-grow: 1;

  h4 {
    margin: 0;
  }

  .notification-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .notification-date {
      font-size: 12px;
      color: #898888;
    }
  }

  .notification-description {
    font-size: 14px;
  }
}

.unreaded {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.readed {
  color: #898888;
  box-shadow: none;
  background: rgb(246, 244, 244);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
