@import "style/global.scss";
@import "style/variables.scss";
@import "style/mixins.scss";



@tailwind base;
@tailwind components;
@tailwind utilities;


// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";