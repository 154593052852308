body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

.error {
  color: red;
  font-size: 12px;
}

.show-password {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    color: var(--light-font-color);
  }
}

.css-1f8sh1y {
  height: 180px !important;
}

.slick-track {
  display: flex;
  gap: 20px;
}
input {
  accent-color: var(--custom-vr-main-color);
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  z-index: 1;

  svg {
    color: var(--custom-vr-main-color);
  }
}

input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 1; /*Change the opacity between 0 and 1*/
}

.danger {
  color: var(--danger-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}
.react-multi-carousel-dot-list {
  bottom: -12% !important;
  gap: 2px !important;

  button {
    width: 15px !important;
    height: 4px !important;
    background-color: var(--border-color) !important;
    border-radius: 0 !important;
    border: none !important;
    margin: 0 !important;
  }
}

.react-multi-carousel-dot--active button {
  background-color: var(--custom-vr-main-color) !important;
}
.react-multiple-carousel__arrow {
  height: 100% !important;
  border-radius: 0 !important;
  width: 6% !important;
  opacity: 0 !important;
  background: rgba(0, 0, 0, 0.3) !important;
  transition: all 0.2s;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: -7% !important;
}
.react-multi-carousel-list {
  &:hover {
    .react-multiple-carousel__arrow {
      opacity: 1 !important;
      transition: all 0.2s;
    }
  }
}

.video {
  padding-top: 52%;
}

