.title {
  color: var(--custom-vr-main-color);
  font-weight: 700;
   margin-bottom: 1rem;
  font-size: 1.25rem;

}


.carousel-container{
  display: flex;
 overflow: visible !important;
}
.slide-item {
  height: 100% !important;
  padding: 0 5px !important;

}
.slide-item:nth-child(1){
  padding-left: 0 !important;
}
// .slide-item:nth-child(5){
//   padding-right: 0 !important;
// }
.slide-item:last-child{
  padding-right: 0 !important;
}

.customArrow {
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.197);
}
