.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .forgot-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;

    .input-container {
      display: flex;
      flex-direction: column;
    }
  }
}

.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
    color: var(--light-font-color);
  }

  svg {
    color: var(--custom-vr-main-color);
    font-size: 4rem;
  }
}

.send-again {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.7;
}
