.modal-card {
  padding: 10px 20px;
  position: relative;
  border-radius: 5px;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}

.primary {
  backdrop-filter: blur(5px);
  background: rgba(245, 245, 245, 0.4);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(139, 102, 73, 0.5);
  border-radius: 5px;
}

.white {
  background-color: white;
}

.black {
  background-color: #000000;
  padding: 30px;
}

.secondary {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  backdrop-filter: blur(5px);
  padding: 30px;
}
