.container {
    padding: 3rem 0 4rem 5.3rem;


    .title {
        margin: 0;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .videos {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }


}