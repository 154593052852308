.container{
    width: 100vw;
    height: 200vh;
    background: #1e1c1c;
    * {
        color: white !important;
      }
    .videoPlayer{
        position: relative;
        height: 100vh;
        .overlay{
            position: absolute;
            top: 0;
            background: black;
            z-index: 999;
            width: 100%;
            height: 100%;
    
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
        }
    }
     
    .informUser {
        width: 100%;
        height: 100vh;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .arrow {
          font-size: 5rem;
          animation: smoothBounce 1.5s infinite;
        }
      }
      .video {
        // height: 300px;
        padding-top: 52% !important;
      }
}

@keyframes smoothBounce {
    0%,
    100% {
      transform: translateY(0);
      animation-timing-function: ease-in;
    }
    50% {
      transform: translateY(-10px);
      animation-timing-function: ease-out;
    }
  }

