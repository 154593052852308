.container {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 100%;
  height: 100vh;
  position: relative;

  p {
    margin: 0;
  }

  .wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.2)
    );
    height: 100vh;

    .main {
      position: relative;
      height: 80vh;
      color: white;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      padding: 4rem 6rem 0 6rem;

      .content {
        width: 100%;

        .content-top {
          .title {
            text-transform: uppercase;
          }
          .sub-title {
            font-size: 0.975rem;
            margin: 1rem 0;
          }
        }

        .content-middle {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .button-box {
            display: flex;
            align-items: center;
            margin: 2rem 0;

            .duration {
              height: 40px;
              opacity: 1;
              display: flex;
              align-items: center;

              &-line-box {
                margin-left: 20px;
                width: 85%;
                height: 100%;
                display: flex;
                align-items: center;
              }

              &-line {
                width: 40vw;
                height: 30%;
                border-radius: 10px;
                background-color: #fff;
                margin: 0 10px;
              }

              &-text {
                margin-left: 15px;
                font-size: 1rem;
                color: #fff;
              }
            }
          }
        }

        .content-bottom {
          width: 50vw;
        }
      }
    }
  }

  .cancel-button {
    color: white !important;
  }

  .icon-button {
    width: 50px !important;
    height: 50px !important;
    color: white !important;
    padding: 0;
  }

  .demo{

    height: 100%;
    width: 100%;

    video{
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
}
