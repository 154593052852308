$transition: 0.4s;

.container {
  position: relative;

  height: 150px;
  transition: $transition;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title {
    &-box {
      position: absolute;
      bottom: -34px;
      width: 100%;
      transition: $transition all;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
      opacity: 0.8;

    }

    &-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      color: #fff;
      border-bottom: 1px solid transparent;
      transition: $transition all;


      &-left {
        display: flex;
        align-items: center;
        transition: $transition;

      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        rotate: 270deg;
        transition: $transition all;
      }
    }

    &-duration {
      width: 100%;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;

      &-line-box {
        width: 85%;
        height: 20px;
        display: flex;
        align-items: center;
      }

      &-line {
        width: 85%;
        height: 6px;
        border-radius: 10px;
        background-color: #fff;

      }

      &-text {
        font-size: 0.75rem;
        color: #fff;
      }
    }
  }

  &:hover {
    transform: scale(1.05);

    .title-box {
      bottom: 0;
      transition: $transition all;

      .title-text {
        border-bottom: 1px solid rgba(255, 255, 255, 0.501);
        transition: $transition all;

        .title-text-right {
          rotate: 90deg;
          transform: rota;
          transition: $transition all;
        }
      }
    }
  }
}

