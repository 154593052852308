.side-bar {
  width: var(--side-bar-width);
  padding: 6rem 2rem;
  
  .content {
    width: 100%;

    display: flex;
    flex-direction: column;

    .link {
      color: #000;
      text-decoration: none;
      padding: 15px;
      cursor: pointer;


      .title {
        position: relative;
      }

      .notification {
        right: -20px;
      }

      &.active {
        color: var(--custom-vr-main-color);
        background: var(--light-primary-background);
        border-radius: 10px;
      }
    }
  }
}