.nav-bar {
  width: 100%;
  background-color: var(--light-background-overlay);
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  img{
    height: 40px;
  }

  >div {
    color: white;
  }

  .account {
    display: flex;
    align-items: center;
    color: black;

    .profile-icon {
      position: relative;
    }

  }
  .notifications-batch{
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }

  .icon-button {
    padding: 0;
    margin-left: 12px;
  }

  .account-circle {
    color: black;
  }
}